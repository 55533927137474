<template>
  <div class="textcatd-item-theme textcatd-theme-1">
    <div class="textcard-item-container" :style="{ backgroundColor: textcardBG }">
      <div v-if="item.subType == 1" class="textcard-title">
        <img src="@/assets/news/more-explore.png" alt="">More to explore
      </div>
      <div v-if="item.subType == 2" class="textcard-title">
        <img src="@/assets/news/hottest.png" alt=""> Hottest
      </div>
      <div class="textcard-item-list-row">
        <div v-for="(data,i) in item.subList" :key="`textcard-${i}`" class="textcard-item-lists"
          @click="goLanding(data.id)">
          <div class="textcard-item-lists-title">
            · {{ data.title }}
          </div>
          <div v-if="item.subType == 1" class="textcard-item-lists-date">
            <img src="@/assets/news/dates.png" alt="">
            {{ $global.formatDate($global.dateStringToTimestamp(data.published), 'MM-dd-yyyy') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '@/css/module/textcard/theme_1.scss';

export default {
  props: {
    item: {
      require: true,
      type: Object,
      default () {
        return {}
      }
    },
    themeConfig: {
      required: true,
      type: Object,
    },
    textCardConfig: {
      type: Array,
      required: true,
    }
  },
  computed: {
    textcardBG () {
      console.log(this.textCardConfig);
      let defaultConfig = this.textCardConfig[0]; // 默认配置项为第一个
      let matchedConfig = null;

      // 遍历 textCardConfig 以查找与 subType 匹配的项
      this.textCardConfig.forEach(config => {
        if (config.typeName.toLowerCase() === this.getTypeName(this.item.subType)) {
          matchedConfig = config;
        }
      });

      // 如果找到匹配项，返回匹配项的背景色，否则返回默认配置项的背景色
      return matchedConfig ? matchedConfig.background : defaultConfig.background;
    }
  },
  methods: {
    getTypeName (subType) {
      // 根据 subType 返回对应的 typeName
      switch (subType) {
        case 1: return 'more';
        case 2: return 'hot';
        default: return '';
      }
    },
    goLanding (id) {
      console.log(id);
      this.$eventrackFb('news_goto_detail', 'click', {
        id
      });
      this.$router.push({ name: 'landing', query: { id, thme: this.themeConfig.landType } });
    },
  }
};
</script>
